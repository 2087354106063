
.drawer > div {
    background-color: #151E2F;
    width: 15%;
}

.options {
    padding: 10px;
    border: 1px solid darkgrey;
    cursor: pointer;
    width: 300px;
    min-height: 60px;
    max-height: 300px;
    border-radius: 15px;
    background-color: rgb(250, 250, 250);
    box-shadow: 2px 4px 8px #c5c5c5;
    transition: all 300ms;
    font-size: 24px;
}

.category-container {
    display: flex;
    flex-direction: column;
    margin: 14px 0;
}

.option {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    transition: all 300ms;
    font-size: 22px;
}

option:hover {
    background-color: brown;
    color: white;
}

.upload-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid black;
    padding: 15px;
    width: 100%;
}

.image-container, .new-category {
    display: flex;
    flex-direction: column;
}

.image-container > * {
    margin: 12px 0;
}

.category-input {
    padding: 8px;
    border: 1px solid grey;
    border-radius: 12px;
    font-size: 22px;
}