.company {
  width: 40%;
  margin: auto;
}

.title-center {
  font-size: 36px;
  text-align: center;
  color: white;
  margin: 230px auto;
  opacity: 0.8;
  font-family: serif ;
  text-transform: uppercase;
}

.company-title {
  color: white;
  font-family: serif sans-serif !important;
  padding: 5px;
  opacity: 0.9;
  text-align: center;
  font-size: 34px;
}

.sub-title {
  margin: 0;
  padding: 0;
  color: orange;
  font-size: 18px;
  font-family: serif sans-serif !important;
  text-align: center;
  margin-left: 60px;
}

.header {
  /* background-position: 'center'; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 5px;
  z-index: -2;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url("../assets/card3.png");
}

.gallery {
  border: 1px solid black;
  background: orange;
  border-radius: 20px;
  margin: 10px 20px;
  width: 300px;
}

.contact-box {
  margin: 5px;
  padding: 20px 10px;
  background: #F4F4F4;
  display: flex !important;
}

@media screen and (max-width: 785px) {
  .company {
    width: 48% !important;
    top: 43% !important;
    left: 25% !important;
  }

  .work-container {
    max-width: 55% !important;
  }

  .card-container {
    flex-direction: column;
    width: 100% !important;
  }

  .card {
    width: 100% !important;
  }

  .gallery {
    margin: auto;
    width: 70% !important;
  }

  .gallery-info {
    max-width: 100% !important;
  }

  .work-container {
    margin: 25px !important;
  }

  .card {
    width: 30em !important;
    margin: 0 !important;
    padding: 5px !important;
  }

  .card-img {
    width: 90% !important;
    margin: 10px !important;
  }
}

@media screen and (max-width: 520px) {
  .header {
    height: 90vh !important;
  }

  .card-container {
    width: 100% !important;
    padding: 0 !important
  }

  .work-container {
    max-width: 100% !important;
    width: 100% !important;
    margin: 0 !important;
  }

  .gallery {
    width: 80% !important;
  }

  .contact-form {
    width: 85% !important;
    margin-bottom: 10em;
  }

  .card {
    width: 100% !important;
    margin: 0 !important;
    padding: 5px !important;
  }

  .our-work {
    top: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    margin-bottom: 16em !important;
  }

  .contact-container {
    top: 0 !important;
    padding: 0 !important;
  }
}

@media screen and (max-width: 380px) {
  .company {
    width: 82% !important;
    left: 20px !important;
    top: 35px !important;
  }

  .card-container {
    margin-left: 5% !important;
  }

  .gallery {
    width: 100% !important;
  }

  .card {
    width: 90% !important;
    margin: 0 !important;
    padding: 5px !important;
  }
}