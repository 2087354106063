@keyframes pop {
  0% { transform: translate(-50%, -50%) scale(1.25, 0.75); }
  50% { transform: translate(-50%, -150%) scale(1, 1); }
  55% { transform: translate(-50%, -150%) rotate(15deg); }
  60% { transform: translate(-50%, -150%) rotate(-15deg); }
  65% { transform: translate(-50%, -150%) rotate(5deg); }
  70% { transform: translate(-50%, -150%) rotate(-15deg); }
  100% { transform: translate(-50%, -50%) scale(1.05, 0.75); }
}

.box {
  transform: translate(-2%, -2%);
  animation: pop 8s infinite;
}

@media screen and (max-width: 785px) {
  .box {
    position: relative;
    top: 15em;
    left: 20%;
    width: 35% !important;
  }
}

@media screen and (max-width: 480px) {
  .box {
    top: 13em;
    left: 35%;
    width: 70% !important;
  }

  .about {
    top: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
}