
.container {
    /* position: relative;
    top: 5em; */
    text-align: center;
    width: 34%;
    max-height: 130px;
    margin: 10px auto;
    padding: 10px;
    background: linear-gradient(90deg, rgba(152,150,150,1) 0%, rgba(223,223,223,1) 0%);
    border: none;
    border-radius: 30px;
}

.type {
    position: relative;
    bottom: 15px;
    font-size: 28px;
    font-family: serif;
    text-transform: capitalize;
}

.select {
    width: 30%;
    max-height: 100px;
    color: black;
    border: 0;
    outline: 0;
    background: none;
    position: relative;
    bottom: 15px;
    text-align: center;
    font-size: 20px;
}

.select-menu {
    padding: 20px;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    font-size: 1.70em;
    background: white;
    margin-top: 30px;
    min-height: 10%;
    height: 200px;
    overflow: auto;
    border-radius: 20px;
}

.item {
    font-family: serif;
}

@media screen and (max-width: 480px) {
    .container {
        width: 90% !important;
    }
}